<template lang="html">
  <div class="header">

    <label for="company">Kompanija</label>
    <select name="company" id="companySelect" v-model="company" @change="onChangeType($event)">
       <option  v-for="cmp in companys" v-bind:key="cmp">{{cmp}}</option>
    </select>

    <label for="godina">Godina</label>
    <select name="godina" id="godina"  @change="onChangeGodina($event)" >
       <option  v-for="god in godine" v-bind:key="god">{{god}}</option>
    </select>

    <label for="racun">Broj Racuna</label>
    <select name="racun" id="racun" v-model="racunBrojSelect" @click="onChangeRacun($event)">
       <option  v-for="rb in racuni" v-bind:key="rb">{{rb}}</option>
    </select>

  </div>

  <div class="faktura" id="faktura">

  <div class="" >
    <div class="" v-if="trenutnaFakturaBroj==0" style="display: inline-block;">
      <p ><b>KEY SOLUTIONS CONSALTING PR</b></p>
    </div>
    <div class="" v-if="trenutnaFakturaBroj==1 || trenutnaFakturaBroj==6" style="display: inline-block;">
      <p ><b>KEY SOLUTIONS 1 ZORICA ILIĆ PR</b></p>
    </div>
    <div class="" v-if="trenutnaFakturaBroj==2" style="display: inline-block;">
      <p ><b>IVANA MLADENOVIĆ PR KONSULTANTSKE USLUGE KEY SOLUTIONS FOR HR BEOGRAD</b></p>
    </div>
    <div class="" v-if="trenutnaFakturaBroj==3" style="display: inline-block;">
      <p ><b>KEY SOLUTIONS FOR YOUR BUSINESS NATAŠA VASIĆ PR</b></p>
    </div>
    <div class="" v-if="trenutnaFakturaBroj==4" style="display: inline-block;">
      <p ><b>KEY SOLUTIONS FOR YOUR BUSINESS NATAŠA VASIĆ PR</b></p>
    </div>
    <div class="" v-if="trenutnaFakturaBroj==5" style="display: inline-block;">
      <p ><b>KEY SOLUTIONS CONSALTING PR</b></p>
    </div>

    <div class="" v-if="trenutnaFakturaBroj==0" style="display: inline-block;float:right;" >
      <p><b>PIB: 106951145</b></p>
    </div>
    <div class="" v-if="trenutnaFakturaBroj==1 || trenutnaFakturaBroj==6" style="display: inline-block;float:right;" >
      <p><b>PIB: 110274733</b></p>
    </div>
    <div class="" v-if="trenutnaFakturaBroj==2" style="display: inline-block;float:right;" >
      <p><b>PIB: 112593631</b></p>
    </div>
    <div class="" v-if="trenutnaFakturaBroj==3" style="display: inline-block;float:right;" >
      <p><b>PIB: 112420198</b></p>
    </div>
    <div class="" v-if="trenutnaFakturaBroj==4" style="display: inline-block;float:right;" >
      <p ><b>PIB: 112420198</b></p>
    </div>
    <div class="" v-if="trenutnaFakturaBroj==5" style="display: inline-block;float:right;" >
      <p><b>PIB: 106951145</b></p>
    </div>
  </div>

  <div class="container">



    <div class="" v-if="trenutnaFakturaBroj==0">
      <p >Beograd, Nedeljka Gvozdenovića 12/2</p>
    </div>
    <div class="" v-if="trenutnaFakturaBroj==1 || trenutnaFakturaBroj==6">
      <p >Beograd, Gandijeva 167</p>
    </div>
    <div class="" v-if="trenutnaFakturaBroj==2">
      <p >Beograd, Nedeljka Gvozdenovića 16a</p>
    </div>
    <div class="" v-if="trenutnaFakturaBroj==3">
      <p >Beograd, Vladimira Popovića 28/12a</p>
    </div>
    <div class="" v-if="trenutnaFakturaBroj==4">
      <p >Beograd, Vladimira Popovića 28/12a</p>
    </div>
    <div class="" v-if="trenutnaFakturaBroj==5">
      <p >Beograd, Nedeljka Gvozdenovića 12/2</p>
    </div>






  <div class="" v-if="trenutnaFakturaBroj==0">
      <p style="float:right">Matični broj: 62393211</p>
  </div>
  <div class="" v-if="trenutnaFakturaBroj==1 || trenutnaFakturaBroj==6">
      <p style="float:right">Matični broj: 64777335</p>
  </div>
  <div class="" v-if="trenutnaFakturaBroj==2">
      <p style="float:right">Matični broj: 66216195</p>
  </div>
  <div class="" v-if="trenutnaFakturaBroj==3">
      <p style="float:right">Matični broj: 66090230</p>
  </div>
  <div class="" v-if="trenutnaFakturaBroj==4">
      <p style="float:right">Matični broj: 66090230</p>
  </div>
  <div class="" v-if="trenutnaFakturaBroj==5">
      <p style="float:right">Matični broj: 62393211</p>
  </div>



    <div class="" v-if="trenutnaFakturaBroj==0 || trenutnaFakturaBroj==1 || trenutnaFakturaBroj==2|| trenutnaFakturaBroj==5 || trenutnaFakturaBroj==6">
      <p>Telefon (011) 4084678</p>
    </div>

    <div class="" v-if="trenutnaFakturaBroj==3 || trenutnaFakturaBroj==4">
      <p>Telefon: +381 (0)65 8502322</p>
    </div>


    <div class="">

    </div>



    <div class="" v-if="trenutnaFakturaBroj==0 || trenutnaFakturaBroj==1 || trenutnaFakturaBroj==2 || trenutnaFakturaBroj==3">
      <p><b>Tekući račun:</b></p>
    </div>
    <div class="" v-if="trenutnaFakturaBroj==4 || trenutnaFakturaBroj==5 || trenutnaFakturaBroj==6">
      <p><b>Devizni račun:</b></p>
    </div>


    <div class="">
      <p style="float:right">Šifra delatnosti: 7022</p>
    </div>




    <div class="" v-if="trenutnaFakturaBroj==0">
      <p><b>160-349895-28 Banca Intesa</b></p>
    </div>
    <div class="" v-if="trenutnaFakturaBroj==1">
      <p><b>160-491781-06 Banca Intesa</b></p>
    </div>
    <div class="" v-if="trenutnaFakturaBroj==2">
      <p><b>160600000122415310 Banca Intesa</b></p>
    </div>
    <div class="" v-if="trenutnaFakturaBroj==3">
      <p><b>160600000103471016 Banca Intesa</b></p>
    </div>
    <div class="" v-if="trenutnaFakturaBroj==4">
      <p><b>160600000120909482 Banca Intesa</b></p>
    </div>
    <div class="" v-if="trenutnaFakturaBroj==5">
      <p><b>00-514-0026299.7 Banca Intesa</b></p>
    </div>
    <div class="" v-if="trenutnaFakturaBroj==6">
      <p><b>160600000179528716 Banca Intesa</b></p>
    </div>

  </div>

  <div class="drugi">
    <div class="sesti">
      <p>Platilac:</p>
      <p>{{platilac}}</p>
    </div>
    <div class="sesti">
      <p>PIB:</p>
      <p>{{pib}}</p>

    </div>
    <div class="sesti">
      <p>MB:</p>
      <p>{{mb}}</p>

    </div>
    <div class="sesti">
      <p>Ime kompanije:</p>
      <p>{{imeKompanije}}</p>
    </div>
    <div class="sesti">
      <p>Adresa:</p>
      <p>{{adresa}}</p>
    </div>
    <div class="sesti">
      <p>Država:</p>
      <p>{{drzava}}</p>
    </div>
  </div>

  <div class="">
    <p style="display: inline-block;"><b>RAČUN BROJ:&nbsp;&nbsp;</b></p>
      <p style="display: inline-block;">{{racunBroj}}/</p>
      <p style="display: inline-block;">{{racunGodina}}</p>
    </div>





  <div class="treci">
    <div class="">

    </div>

    <div class="">


    <div class="">
      <p >Mesto izdavanja:&nbsp;&nbsp;Beograd</p>
    </div>

  <div >
      <p style="display: inline-block;">Datum izdavanja:&nbsp;&nbsp;</p>
      <p style="display: inline-block;">{{datumIzdavanja}}</p>
  </div>

    <div class="">
      <p style="display: inline-block;"> <b>Valuta:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></p>
      <p style="display: inline-block;">{{valuta}}</p>
    </div>

    <div class="">
      <p style="display: inline-block;"> <b>Datum prometa:&nbsp;&nbsp;</b></p>
      <p style="display: inline-block;">{{datumPrometa}}</p>
    </div>

      </div>
  </div>

  <div class="cetvrti">
    <div class="">
      <p >Naziv usluge</p>
    </div>
    <div class="">
      <p style="float:right">Cena usluge</p>
    </div>
  </div>

  <div class="cetvrti" >
    <div class="" >
      <p>{{nazivUsluge}}</p>
    </div>
    <div class="">
      <p style="float:right">{{cenaUsluge}}</p>
    </div>
  </div>


    <div  style="float:right">
      <p style="display: inline-block;"><b>Iznos:&nbsp;&nbsp;</b></p>
      <p style="display: inline-block;">{{iznos}}</p>
    </div>


  <div class="">
    <div class="flexInline">
      <p>Slovima:&nbsp;&nbsp;</p>
      <p>{{slovima}}</p>
    </div>



    <p v-if="trenutnaFakturaBroj==0"><b>Uplatu izvršiti na račun kod Banca Intesa AD Beograd: 160-349895-28</b></p>
    <p v-if="trenutnaFakturaBroj==1"><b>Uplatu izvršiti na račun kod Banca Intesa AD Beograd: 160-491781-06</b></p>
    <p v-if="trenutnaFakturaBroj==2"><b>Uplatu izvršiti na račun kod Banca Intesa AD Beograd: 160600000122415310</b></p>
    <p v-if="trenutnaFakturaBroj==3"><b>Uplatu izvršiti na račun kod Banca Intesa AD Beograd: 160600000103471016</b></p>
    <p v-if="trenutnaFakturaBroj==4"><b>Uplatu izvršiti na račun kod Banca Intesa AD Beograd: 160600000120909482</b></p>
    <p v-if="trenutnaFakturaBroj==5"><b></b></p>
    <p v-if="trenutnaFakturaBroj==6"><b>Uplatu izvršiti na račun kod Banca Intesa AD Beograd: 160600000179528716</b></p>





    <p v-if="trenutnaFakturaBroj==0">NAPOMENA O PORESKOM OSLOBOĐENJU: Key Solutions consalting nije u sistemu PDV-a</p>
    <p v-if="trenutnaFakturaBroj==1 || trenutnaFakturaBroj==6">NAPOMENA O PORESKOM OSLOBOĐENJU: Key Solutions 1 nije u sistemu PDV.</p>
    <p v-if="trenutnaFakturaBroj==2">NAPOMENA O PORESKOM OSLOBOĐENJU: IVANA MLADENOVIĆ PR KONSULTANTSKE USLUGE KEY SOLUTIONS FOR HR BEOGRAD nije u sistemu PDV.</p>
    <p v-if="trenutnaFakturaBroj==3">NAPOMENA O PORESKOM OSLOBOĐENJU: Key Solutions For Your Business Nataša Vasić PR nije u sistemu PDV.</p>
    <p v-if="trenutnaFakturaBroj==4">NAPOMENA O PORESKOM OSLOBOĐENJU: Key Solutions For Your Business Nataša Vasić PR nije u sistemu PDV.</p>
    <p v-if="trenutnaFakturaBroj==5">NAPOMENA O PORESKOM OSLOBOĐENJU: Key Solutions Consalting nije u sistemu PDV-a</p>

  </div>

  <div class="footer">
    <div class="">

    </div>
    <div class="">
      <p style="text-align:center;">M.P.</p>
    </div>


    <div class="" v-if="trenutnaFakturaBroj==0 || trenutnaFakturaBroj==2 || trenutnaFakturaBroj==5">
      <p style="float:right">Fakturisao: Milenko Mladenović</p>
    </div>

    <div class="" v-if="trenutnaFakturaBroj==3 || trenutnaFakturaBroj==4 ">
      <p style="float:right">Fakturisao: Nataša Vasić</p>
    </div>

    <div class="" v-if="trenutnaFakturaBroj==1 || trenutnaFakturaBroj==6">
      <p style="float:right">Fakturisao: Zorica Ilic</p>
    </div>



  </div>

  </div>

  <button type="button" name="button" @click="makePDFVQ">pdf</button>


</template>

<script>

// eslint-disable-next-line no-unused-vars
import html2canvas from 'html2canvas';
// eslint-disable-next-line no-unused-vars
import jsPDF from 'jspdf';
// eslint-disable-next-line no-unused-vars
import {mapActions,mapGetters} from 'vuex';
export default {
  computed: mapGetters(['faktures']),
  data(){
    return{

      company:"KEY SOLUTIONS CONSALTING PR",
      companys:["KEY SOLUTIONS CONSALTING PR","KEY SOLUTIONS 1 ZORICA ILIĆ PR", "KEY SOLUTIONS 1 ZORICA ILIĆ PR (DEVIZNI)","IVANA MLADENOVIĆ PR KONSULTANTSKE USLUGE KEY SOLUTIONS FOR HR BEOGRAD","KEY SOLUTIONS FOR YOUR BUSINESS NATAŠA VASIĆ PR","KEY SOLUTIONS FOR YOUR BUSINESS NATAŠA VASIĆ PR (DEVIZNI)","KEY SOLUTIONS CONSALTING PR (DEVIZNI)"],
      trenutnaFakturaBroj: 0,

      godina:"2022",
      godine:["2022","2023","2024"],

      racunBrojSelect:"",
      racuni:[],

      fakture:[],


      faktura:"",
      platilac:"",
      pib:"",
      mb:"",
      imeKompanije:"",
      adresa:"",
      drzava:"",
      datumIzdavanja:"",
      datumPrometa:"",
      valuta:"",
      nazivUsluge:"",
      cenaUsluge:"",
      iznos:"",
      slovima:"",
      racunBroj:"",
      racunGodina:"",

    }
  },

  methods:{
    ...mapActions(['getAllFaktures']),
    onChangeGodina(event){
       this.godina = event.target.value;
      this.loadFakturas()
    },
    loadFakturas(){

      this.racuni = [];
      for (var i = 0; i < this.faktures.length; i++) {
          if(this.faktures[i].type==this.trenutnaFakturaBroj){
            if(this.godina == this.faktures[i].racunGodina){
            this.racuni.push(this.faktures[i].racunBroj);}
          }
        }
    },
    onChangeType(event) {


        if(event.target.value=="KEY SOLUTIONS CONSALTING PR"){
          this.trenutnaFakturaBroj=0;
        }
        if(event.target.value=="KEY SOLUTIONS 1 ZORICA ILIĆ PR"){
          this.trenutnaFakturaBroj=1;
        }
        if(event.target.value=="IVANA MLADENOVIĆ PR KONSULTANTSKE USLUGE KEY SOLUTIONS FOR HR BEOGRAD"){
          this.trenutnaFakturaBroj=2;
        }
        if(event.target.value=="KEY SOLUTIONS FOR YOUR BUSINESS NATAŠA VASIĆ PR"){
          this.trenutnaFakturaBroj=3;
        }
        if(event.target.value=="KEY SOLUTIONS FOR YOUR BUSINESS NATAŠA VASIĆ PR (DEVIZNI)"){
          this.trenutnaFakturaBroj=4;
        }
        if(event.target.value=="KEY SOLUTIONS CONSALTING PR (DEVIZNI)"){
          this.trenutnaFakturaBroj=5;
        }
        if(event.target.value=="KEY SOLUTIONS 1 ZORICA ILIĆ PR (DEVIZNI)"){
          this.trenutnaFakturaBroj=6;
        }
       

        this.loadFakturas();


    },

    onChangeRacun(event){

      this.faktura="";
      this.platilac="";
      this.pib="";
      this.mb="";
      this.imeKompanije="";
      this.adresa="";
      this.drzava="";
      this.datumIzdavanja="";
      this.datumPrometa="";
      this.valuta="";
      this.nazivUsluge="";
      this.cenaUsluge="";
      this.iznos="";
      this.slovima="";
      this.racunBroj="";
      this.racunGodina="";


      for (var i = 0; i < this.faktures.length; i++) {
        if(this.trenutnaFakturaBroj==this.faktures[i].type){
          if(event.target.value==this.faktures[i].racunBroj){
            this.faktura=this.faktures[i].type ;
            this.platilac=this.faktures[i].platilac ;
            this.pib=this.faktures[i].pib ;
            this.mb=this.faktures[i].mib ;
            this.imeKompanije=this.faktures[i].imeKompanije ;
            this.adresa=this.faktures[i].adresa ;
            this.drzava=this.faktures[i].drzava ;
            this.datumIzdavanja=this.faktures[i].datumIzdavanja ;
            this.datumPrometa=this.faktures[i].datumPrometa ;
            this.valuta=this.faktures[i].valuta ;
            this.nazivUsluge=this.faktures[i].nazivUsluge ;
            this.cenaUsluge=this.faktures[i].cenaUsluge ;
            this.iznos=this.faktures[i].iznos ;
            this.slovima=this.faktures[i].slovima ;
            this.racunBroj=this.faktures[i].racunBroj ;
            this.racunGodina=this.faktures[i].racunGodina ;
          }
        }
      }
    },
    makePDF(){
      window.html2canvas=html2canvas;
      //p = portrait (moze i l za landscape)
      //pt za measurement
      //a4 za format
      var doc = new jsPDF("p","pt","a3");
      doc.html(document.querySelector("#faktura"),{
        callback: function(pdf){
          pdf.save("faktura.pdf");
        }
      });
    },

    async makePDFVQ(){

      var downloading = document.getElementById("faktura");
      var doc = new jsPDF("p","pt","a3");

      await html2canvas(downloading,{
        scale: 2,
        allowTaint : true,
        useCORS: true,
        height:1190,
        width:840,
      }).then((canvas)=>{
        doc.addImage(canvas.toDataURL("image/png"),'PNG',0,0,840, 1190);
      });

      doc.save("Document.pdf");

    },
  },

  async created(){
    await this.getAllFaktures();

    this.loadFakturas();

 
  }

}
</script>

<style lang="css" scoped>

.faktura{
  border-style: solid;
  border-color: black;
  border-width: thin;
  padding: 30px;

  height:1190px;
  width:840px;
}
.container {

  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". ."
    ". ."
    ". ."
    ". .";
}
.stoposto{
  width:100%;
}

.drugi {
  border-style: solid;
  border-color: black;
  border-width: thin;
  border-radius: 15px;
  padding: 10px;
  width:80%;


}

.treci{
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1.3fr 0.7fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". .";
}
.cetvrti{
  border-style: solid;
  border-color: black;
  border-width: thin;
  padding: 5px;
  margin-bottom: 5px;

  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1.6fr 0.4fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". .";
}

.cetvrtiVeci{
  border-style: solid;
  border-color: black;
  border-width: thin;
  padding: 5px;
  margin-bottom: 5px;


  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1.6fr 0.4fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". .";
}

.peti{
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". .";
}


.sesti{
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 0.4fr 1.6fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". .";
}
.footer{
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". . .";
}
.header{

  padding: 15px;
  border-style: solid;
  border-color: black;
  border-width: thin;

  margin-bottom: 10px;


  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". ."
    ". ."
    ". .";

}
.flexInline{
   display: flex;
}
</style>
